import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import Price from 'Pricing/Price';
import Image, { Ratio as ImageRatio } from 'Shared/Image/Ratio';
import { pixelsToUnit, minMediumMediaQuery, white, thin, minSmallMediaQuery, minTinyMediaQuery } from 'Shared/Style';
import OrderLineItemViewModel from 'Order/Details/OrderLineItemViewModel.type';
import { alto, mountainmeadow, black, whiteLilacFaded, tundora } from 'Shared/Style/colors';
import { iota, gamma, epsilon, delta, sigma } from 'Shared/Style/typography';
import { small } from 'Shared/Style/spacing';
import Link from 'Shared/Link';

type PropType = {
  products: OrderLineItemViewModel[];
  orderStatus: string;
  shippingFee: number;
  paymentFee: number;
  totalPrice: number;
  currency: string;
};

export default (props: PropType) => {
  const { products, orderStatus } = props;
  return (
    <ProductsList>
      <h4>
        {translate('/Account/MyPages/OrderHistory/Status')}: <StatusValue>{orderStatus}</StatusValue>
      </h4>
      <ProductWrapper>
        {!!products &&
          products.length > 0 &&
          products.map((item: OrderLineItemViewModel, idx: number) => (
            <ProductItem key={idx}>
              <Product>
                <CustomLink to={item.productUrl}>
                  <Image
                    css={item.imageUrl && { backgroundColor: white }}
                    src={item.imageUrl}
                    ratio={ImageRatio.OneToOne}
                  />
                </CustomLink>
                {!!item && (
                  <>
                    <DetailsContainer>
                      <ProductNameLink to={item.productUrl}>{item.productName}</ProductNameLink>
                      {item.productCode && (
                        <ArticleNumberWrapper>
                          <span>
                            {`${translate('/Account/MyPages/OrderHistory/Detail/ArticleNumber')}: ${item.sku}`}
                          </span>
                        </ArticleNumberWrapper>
                      )}
                      {item.returnQuantity > 0 && (
                        <div>
                          {translate('/Account/MyPages/OrderHistory/Detail/Status')}:
                          {translate('/Account/MyPages/OrderHistory/Returned')}
                        </div>
                      )}
                    </DetailsContainer>
                    <ProductPrice current={item.price} currency={props.currency} />
                  </>
                )}
              </Product>
            </ProductItem>
          ))}
      </ProductWrapper>
      <PaymentWrapper>
        <Payment>
          <SummaryHeader>{translate('/Account/MyPages/OrderHistory/Detail/Shipping')}</SummaryHeader>
          <Cost>
            <Price current={props.shippingFee} currency={props.currency} />
          </Cost>
        </Payment>
        <Payment>
          <SummaryHeader>{translate('/Account/MyPages/OrderHistory/Detail/PaymentCost')}</SummaryHeader>
          <Cost>
            <Price current={props.paymentFee} currency={props.currency} />
          </Cost>
        </Payment>
        <Payment>
          <SummaryHeader css={{ fontWeight: 'bold' }}>
            {translate('/Account/MyPages/OrderHistory/Detail/TotalIncludeVat')}
          </SummaryHeader>
          <TotalPrice current={props.totalPrice} currency={props.currency} />
        </Payment>
      </PaymentWrapper>
    </ProductsList>
  );
};

const SummaryHeader = styled.span({
  textAlign: 'right',
  flexBasis: '60%',
});

const StatusValue = styled.span({
  fontSize: epsilon,
  fontWeight: 'normal',
});

const ProductsList = styled.div({
  backgroundColor: whiteLilacFaded,
  color: tundora,
  minHeight: pixelsToUnit(235),
  overflowY: 'hidden',
  padding: {
    xy: small,
  },
  [minMediumMediaQuery]: {
    padding: {
      x: pixelsToUnit(30),
      y: pixelsToUnit(20),
    },
    paddingBottom: pixelsToUnit(30),
  },
});

const ProductWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  border: {
    y: {
      color: alto,
      width: thin,
      style: 'solid',
    },
  },
  paddingBottom: pixelsToUnit(30),
  marginBottom: pixelsToUnit(15),
  marginTop: pixelsToUnit(5),
  [minMediumMediaQuery]: {
    marginBottom: pixelsToUnit(30),
  },
});

const ProductItem = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const Product = styled.div({
  display: 'flex',
  flexGrow: 1,
  marginTop: pixelsToUnit(30),
  width: '70%',
  [minMediumMediaQuery]: {
    maxHeight: pixelsToUnit(100),
  },
});

const CustomLink = styled(Link, {
  width: pixelsToUnit(15),
  minWidth: pixelsToUnit(51),
  maxHeight: pixelsToUnit(51),
  [minSmallMediaQuery]: {
    maxHeight: 'none',
  },
  [minMediumMediaQuery]: {
    width: '35%',
    maxWidth: pixelsToUnit(101),
  },
});

const DetailsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  flexFlow: 'column-wrap',
  fontSize: sigma,
  flexGrow: 1,
  padding: {
    x: pixelsToUnit(10),
  },
  [minMediumMediaQuery]: {
    padding: {
      x: pixelsToUnit(20),
    },
  },
});

const ProductNameLink = styled(Link, {
  color: black,
  maxWidth: pixelsToUnit(150),
  ':hover': { textDecoration: 'underLine' },
  [minTinyMediaQuery]: { maxWidth: 'initial' },
});

const PaymentWrapper = styled.div({
  position: 'relative',
  float: 'right',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: pixelsToUnit(297),
  width: '100%',
});

const Payment = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexFlow: 'row',
  width: '100%',
});

const Cost = styled.span({
  fontWeight: 'normal',
  fontSize: epsilon,
  letterSpacing: pixelsToUnit(0.23),
  [minMediumMediaQuery]: {
    fontSize: gamma,
  },
});
const TotalPrice = styled(Price, {
  color: mountainmeadow,
  fontSize: delta,
  [minMediumMediaQuery]: {
    fontSize: iota,
  },
});

const ProductPrice = styled(Price, {
  fontSize: sigma,
  alignSelf: 'center',
  [minMediumMediaQuery]: { fontSize: epsilon },
});

const ArticleNumberWrapper = styled.div({
  wordBreak: 'break-word',
});
