/**
 * @ComponentFor OrderDetailsPageViewModel
 */

import * as React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import { EpiProperty, epiPropertyValue } from '@avensia/scope-episerver';
import OrderDetailsPageViewModel from './OrderDetailsPageViewModel.type';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import OrderListProduct from './components/OrderListProduct';
import MyPagesBaseLayout from '../MyPagesBaseLayout';
import { pixelsToUnit, thin, minMediumMediaQuery } from 'Shared/Style';
import { mercurySolid } from 'Shared/Style/colors';
import { sigma, epsilon } from 'Shared/Style/typography';
import { minTinyMediaQuery } from 'Shared/Style/media';

export default function OrderDetailsPage(props: OrderDetailsPageViewModel) {
  const { shippingAddress, billingAddress, paymentMethodName, shippingMethodName, paymentType } = props.order;
  const ordersHistoryUrl = props.breadcrumbs[props.breadcrumbs.length - 2].url;

  return (
    <MyPagesBaseLayout {...props}>
      <Content>
        <TextGroup>
          <TextWrapper>
            <Label>{translate('/Account/MyPages/OrderHistory/Detail/OrderCreated')}:</Label>
            <Text>{props.order.created}</Text>
          </TextWrapper>
          <TextWrapper>
            <Label>{translate('/Account/MyPages/OrderHistory/Detail/CustomerNumber')}:</Label>
            <Text>{props.order.orderGroupId}</Text>
          </TextWrapper>
        </TextGroup>
        <TextGroup>
          <TextWrapper>
            <Label>{translate('/Account/MyPages/OrderHistory/Detail/PaymentStatus')}:</Label>
            <Text>{props.order.paymentStatus}</Text>
          </TextWrapper>
          <TextWrapper>
            <Label>{translate('/Account/MyPages/OrderHistory/OrderStatus')}:</Label>
            <Text>{props.order.orderStatusDisplay}</Text>
          </TextWrapper>
        </TextGroup>
        <TextGroup css={{ marginTop: pixelsToUnit(10) }}>
          <TextWrapper>
            <Label>{translate('/Account/MyPages/OrderHistory/Detail/OrderedItems')}</Label>
          </TextWrapper>
          {epiPropertyValue(props.page.orderMessage) && (
            <TextWrapper>
              <EpiProperty for={props.page.orderMessage} />
            </TextWrapper>
          )}
        </TextGroup>
        <OrderListProduct
          products={props.order.orderLineItems}
          orderStatus={props.order.orderStatusDisplay}
          shippingFee={props.order.shippingFee}
          paymentFee={props.order.paymentFee}
          totalPrice={props.order.totalPrice}
          currency={props.order.currency}
        />
        <AdditionalDetails>
          <DetailsSection>
            <TitleAndDescription>
              <Title>{translate('/Account/MyPages/OrderHistory/Detail/Title/PackageSentTo')}</Title>
              <Description>
                {`${shippingAddress.firstName} ${shippingAddress.lastName}`}
                <div>{shippingAddress.address1}</div>
                <div>{shippingAddress.address2}</div>
                {`${shippingAddress.postalCode} ${shippingAddress.city}`}
              </Description>
            </TitleAndDescription>
            <TitleAndDescription>
              <Title>{translate('/Account/MyPages/OrderHistory/Detail/Title/SelectedDeliveryMethod')}</Title>
              <Description>{shippingMethodName}</Description>
            </TitleAndDescription>
            {/* TODO: Enable this html Description tag if backend data provide and map data here<TitleAndDescription>
              <Title>{translate('/Account/MyPages/OrderHistory/Detail/Title/PaymentMethod')}</Title>
                <Description>
                  Faktura
                  <styled.Div css={{ marginBottom: pixelsToUnit(20) }}>
                    Betalningsmottagare: Skeidarv <br />
                    Bankgiro: 852-7541 <br />
                    Referensnummer: 11103011412198
                  </styled.Div>
                  <styled.Div css={{ marginBottom: pixelsToUnit(20) }}>
                    Efter erhållandet av leveransen/varan, vänligen betala till: <br />
                    IBAN: SE02 9500 0099 6042 0083 8631 <br />
                    BIC: NDEASESS
                  </styled.Div>
                </Description>
            </TitleAndDescription> */}
          </DetailsSection>
          <DetailsSection>
            <TitleAndDescription>
              <Title>{translate('/Account/MyPages/OrderHistory/Detail/Title/InvoiceAddress')}</Title>
              <Description>
                {`${billingAddress.firstName} ${billingAddress.lastName}`}
                <div>{billingAddress.address1}</div>
                <div>{billingAddress.address2}</div>
                <div>{`${billingAddress.postalCode} ${billingAddress.city}`}</div>
                <div>{billingAddress.phoneNumber}</div>
                {billingAddress.email}
              </Description>
            </TitleAndDescription>
            <TitleAndDescription>
              <Title>{translate('/Account/MyPages/OrderHistory/Detail/Title/SelectedPaymentMethod')}</Title>
              <Description>{paymentMethodName}{paymentType ? ` - ${paymentType}` : ''}</Description>
            </TitleAndDescription>
            {/* UnComment if the Data is avalable:
             <TitleAndDescription>
              <Title>{translate('/Account/MyPages/OrderHistory/Detail/Title/OrderType')}</Title>
                <Description>Webshop</Description>
            </TitleAndDescription> */}
          </DetailsSection>
        </AdditionalDetails>
        <ActionButton to={ordersHistoryUrl} appearance={ButtonAppearance.Secondary}>
          {translate('/Account/MyPages/OrderHistory/Detail/ButtonText')}
        </ActionButton>
      </Content>
    </MyPagesBaseLayout>
  );
}
const Content = styled.div({
  display: 'flex',
  maxWidth: pixelsToUnit(840),
  flexDirection: 'column',
  marginTop: pixelsToUnit(25),
  fontSize: sigma,
  [minTinyMediaQuery]: {
    fontSize: epsilon,
  },
  [minMediumMediaQuery]: {
    marginBottom: pixelsToUnit(97),
  },
});

const TextGroup = styled.div({
  marginBottom: pixelsToUnit(20),
});

const AdditionalDetails = styled.div({
  marginTop: pixelsToUnit(50),
  display: 'flex',
  flexGrow: 1,
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

const DetailsSection = styled.div({
  flexBasis: '100%',
  fontSize: pixelsToUnit(16),
  [minMediumMediaQuery]: {
    flexBasis: '45%',
  },
});

const TextWrapper = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
});

const Title = styled(TextWrapper, {
  marginBottom: pixelsToUnit(10),
  height: pixelsToUnit(35),
  fontWeight: 'bold',
  borderBottom: {
    width: thin,
    style: 'solid',
    color: mercurySolid,
  },
});

const Label = styled.label({
  fontWeight: 'bold',
});

const Text = styled.span({
  width: '100%',
  marginLeft: pixelsToUnit(5),
});

const TitleAndDescription = styled(TextGroup, {
  marginBottom: pixelsToUnit(50),
});

const Description = styled(Text, {
  marginLeft: 0,
});

const ActionButton = styled(Button, {
  display: 'flex',
  alignItems: 'center',
  height: pixelsToUnit(45),
  maxWidth: pixelsToUnit(264),
  letterSpacing: pixelsToUnit(0.7),
  textTransform: 'none',
  fontSize: sigma,
  padding: {
    x: pixelsToUnit(27),
    y: 0,
  },
  margin: {
    x: 'auto',
  },
  [minMediumMediaQuery]: {
    marginTop: pixelsToUnit(20),
    margin: {
      x: 0,
    },
  },
});
